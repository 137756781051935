import request from './request';

export function getMenuList(params){
  return request({
    url: 'menu/getMenuList',
    method: 'post',
    data: params
  });
}

export function addMenu(params){
  return request({
    url: 'menu/addMenu',
    method: 'post',
    data: params
  });
}

export function editMenu(params){
  return request({
    url: 'menu/editMenu',
    method: 'post',
    data: params
  });
}

export function deleteMenu(params){
  return request({
    url: 'menu/deleteMenu',
    method: 'post',
    data: params
  });
}


