<template>
    <v-app style="background: transparent" :class="{}">
        <!-- begin:: Content Head -->
        <KTSubheader
                v-if="nav.subheaderDisplay"
                v-bind:breadcrumbs="nav.breadcrumbs"
                v-bind:title="nav.pageTitle"
        />
        <!-- end:: Content Head -->

        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">

                <!--begin::Card-->
                <div class="card card-custom">
                    <div class="card-header flex-wrap border-0 pt-6 pb-0">
                        <div class="card-title">
                            <h3 class="card-label">菜单列表
                                <span class="d-block text-muted pt-2 font-size-sm"></span>
                            </h3>
                        </div>
                        <div class="card-toolbar">

                            <!--begin::Button-->
                            <a href="javascript:;" class="btn btn-primary font-weight-bolder" v-on:click="addRootMenu()">
                                新增一级菜单
                            </a>
                            <!--end::Button-->
                        </div>
                    </div>
                    <div class="card-body">
                        <!--begin: Search Form-->

                        <!--begin::Search Form-->
                        <div class="mb-7">
                            <div class="row align-items-center">
                                <div class="col-lg-9 col-xl-8">
                                    <div class="row align-items-center">
                                        <div class="col-md-4 my-2 my-md-0">
                                            <div class="input-icon">
                                                <input type="text" class="form-control" placeholder="栏目名称" v-model="search_form.name">
                                                <span>
                                                    <i class="flaticon2-search-1 text-muted"></i>
                                                </span>
                                            </div>
                                        </div>
                                        <a href="#" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="searchList()">Search</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!--end::Search Form-->
                        <!--end: Search Form-->

                        <KTDatatable
                            v-bind:list="list"
                            v-bind:column="column"
                            v-bind:showChildren="true"
                            v-bind:showSelect="false"
                            v-bind:showPagination="false"
                            @operateHandler="operateHandler"
                        ></KTDatatable>

                    </div>
                </div>
                <!--end::Card-->
            </div>
            <!--end::Container-->
        </div>

        <v-dialog v-model="dialog_show" persistent max-width="600px">
            <component
                    :key="dialog_id"
                    :title="dialog_title"
                    :visible="dialog_show"
                    :is="dialog_view"
                    :dialog_data="dialog_data"
                    @opeareResultHandler="opeareResultHandler"></component>
        </v-dialog>

    </v-app>
</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>

    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTDatatable from "@/view/content/tables/Datatable.vue";

    import {getMenuList, deleteMenu} from '@/api/menu';
    import KTUtil from "@/assets/js/components/util";
    import addMenu from '@/view/pages/menu/AddMenu';
    import updateMenu from '@/view/pages/menu/UpdateMenu';
    import Swal from "sweetalert2";


    export default {
        name: "menu_view",
        components: {
            KTSubheader,
            KTDatatable,
            addMenu,
            updateMenu
        },
        created: function(){
            var _this = this;
            _this.searchList();
        },
        data() {
            return {
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/menu', 'title':'栏目管理'}],
                    pageTitle: '系统'
                },
                list: [],
                column: [{
                    field: 'name',
                    title: '菜单名称',
                },{
                    field: 'href',
                    title: '连接地址',
                },{
                    field: 'description',
                    title: '菜单描述',
                },{
                    field: 'icon',
                    title: '菜单图标',
                    formatter: function(row) {
                        return '<i class="'+ row.icon +'"></i>';
                    }
                },{
                    field: 'sort',
                    title: '菜单排序',
                },{
                    field: 'create_time',
                    title: '创建时间',
                    width: 200,
                    formatter: function(row) {
                        return KTUtil.timestamp2date(row.create_time);
                    }
                },{
                    field: 'operate',
                    title: '操作',
                    width: 150,
                    formatter: function(row){
                        let html = '' +
                            '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="添加子菜单">'+
                                '<i class="la la-plus icon-lg"></i>'+
                            '</a>'+
                            '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="修改">'+
                                '<i class="la la-edit icon-lg"></i>'+
                            '</a>'+
                            '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="删除">'+
                                '<i class="la la-trash-o icon-lg"></i>'+
                            '</a>';
                        return html;
                    }
                }],
                search_form: {
                    name: ''
                },
                dialog_id: 0,
                dialog_show: false,
                dialog_title: '',
                dialog_view: null,
                dialog_data: null
            }
        },
        methods: {
            searchList() {
                var _this = this;
                getMenuList({'name': _this.search_form.name}).then(function(res){
                    if(res){
                        _this.list = res.response;
                    }
                });
            },
            operateHandler(clazz, row) {
                var _this = this;
                if (clazz.indexOf('la-edit') > -1) {
                    //编辑操作
                    if(row.pid == 0){
                        row.pname = '根菜单';
                    }
                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '编辑菜单';
                    this.dialog_view = updateMenu;
                    this.dialog_data = {
                        'form_data': row,
                    };

                } else if (clazz.indexOf('la-trash-o') > -1) {

                    KTUtil.confirm('操作提示', '是否确认删除该条记录？', function(){
                        deleteMenu({id: row.id}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '删除成功', 'success');
                                _this.searchList();
                            }
                        });
                    });

                } else if (clazz.indexOf('la la-plus icon-lg') > -1) {
                    //新增操作
                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '新增菜单';
                    this.dialog_view = addMenu;
                    this.dialog_data = {
                        'parent_id': row.id,
                        'parent_name': row.name
                    };
                }
            },
            opeareResultHandler(result) {
                if(result){
                    this.searchList();
                }
                this.dialog_show = false;
            },
            addRootMenu() {
                //新增一级菜单操作
                this.dialog_id = new Date().getTime();
                this.dialog_show = true;
                this.dialog_title = '新增菜单';
                this.dialog_view = addMenu;
                this.dialog_data = {
                    'parent_id': 0,
                    'parent_name': '根菜单'
                };
            }

        }
    }
</script>
