<template>
  <div class="card card-custom example example-compact">
    <div class="card-header">
      <h3 class="card-title">{{title}}</h3>
      <button type="button" class="close" @click="cancelForm()">
        <i aria-hidden="true" class="ki ki-close"></i>
      </button>
    </div>

    <!--begin::Form-->
    <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
      <div class="card-body" style="max-height:500px;overflow:auto;">
        <div class="form-group row">
          <label class="col-lg-3 col-form-label">上级菜单:</label>
          <div class="col-lg-6">
            <input type="text" class="form-control" v-model="dialog_data.parent_name" name="parent_name" disabled="disabled">
            <span class="form-text text-muted"></span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-3 col-form-label">菜单名称 <span class="text-danger">*</span>:</label>
          <div class="col-lg-6">
            <input type="text" class="form-control" placeholder="请输入菜单名称" v-model="data_form.name" name="name">
            <span class="form-text text-muted"></span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-3 col-form-label">菜单地址 <span class="text-danger">*</span>:</label>
          <div class="col-lg-6">
            <input type="text" class="form-control" placeholder="请输入菜单地址" v-model="data_form.href" name="href">
            <span class="form-text text-muted"></span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-3 col-form-label">菜单排序:</label>
          <div class="col-lg-6">
            <input type="number" class="form-control" placeholder="请输入菜单排序" v-model="data_form.sort" name="sort">
            <span class="form-text text-muted"></span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-3 col-form-label">菜单图标 <span class="text-danger">*</span>:</label>
          <div class="col-lg-6">
            <div class="btn btn-icon">
              <i v-bind:class="[data_form.icon]"></i>
            </div>
            <div  class="btn btn-icon btn-light-primary" v-on:click="iconchose=true;">
              <i class="flaticon-interface-8"></i>
            </div>
            <KTIconSelect
              :show="iconchose"
              @iconCloseHandler="iconCloseHandler"
              @iconSelectHandler="iconSelectHandler"
            ></KTIconSelect>

            <input type="hidden" class="form-control" placeholder="请选择菜单图标" v-model="data_form.icon" name="icon" ref="icon_input">
            <span class="form-text text-muted">请选择合适的菜单图标</span>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-3 col-form-label">菜单描述:</label>
          <div class="col-lg-6">
            <textarea class="form-control" rows="3" v-model="data_form.description" name="description"></textarea>
            <span class="form-text text-muted"></span>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
        <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>
<script>
  import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
  import KTIconSelect from "@/view/content/dialog/IconSelect.vue";
  import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
  import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
  import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
  import KTUtil from "@/assets/js/components/util";

  import {addMenu} from '@/api/menu';

  export default {
    name: "menu_add",
    props: {
      title: String,
      dialog_data: Object
    },
    components :{
      KTIconSelect
    },
    data() {
      return {
        data_form:{
          pid: this.dialog_data.parent_id,
          name: '',
          href: '',
          icon: '',
          sort: 99,
          description: ''
        },
        iconchose: false
      }
    },
    mounted() {
      const data_form = KTUtil.getById("dataForm");

      //表单数据验证
      this.fv = formValidation(data_form, {
        fields: {
          name: {
            validators: {
              notEmpty: {
                message: "菜单名称不能为空"
              }
            }
          },
          href: {
            validators: {
              notEmpty: {
                message: "菜单地址不能为空"
              }
            }
          },
          icon: {
            validators: {
              notEmpty: {
                message: "菜单图标不能为空"
              }
            }
          },
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
      this.fv.on("core.form.valid", () => {
        var _this = this;

        //按钮加载动画
        const submitButton = this.$refs["data_form_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        addMenu(_this.data_form).then(function (res){
          if (res) {

            KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
            data_form.reset();
            _this.$emit('opeareResultHandler', 1);
          }
          submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
          );
        });
      });
    },
    methods: {
      iconSelectHandler(icon){
        this.iconchose = false;
        this.data_form.icon = icon;
        this.$refs["icon_input"].parentElement.lastChild.remove();

      },
      iconCloseHandler(){
        this.iconchose = false;
      },
      cancelForm(){
        this.$emit('opeareResultHandler', 0);

      }
    }
  }
</script>
